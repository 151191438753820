.whiteLogoStyles {
  height: 4em;
}

.whiteLogoStylesMobile {
  height: 3.8em;
}
/* 
@media (prefers-reduced-motion: no-preference) {
  .whiteLogoStyles:hover {
    animation: whiteLogoStyles-float infinite 3s ease-in-out;
  }
  .whiteLogoStylesMobile {
    animation: whiteLogoStyles-float infinite 3s ease-in-out;
  }
}

@keyframes whiteLogoStyles-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
} */
